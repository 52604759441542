import "./front.scss";

const promises = [];

const action = {
  prepareQuery: function (params) {
    const mandatoryParams = {
      action: "update",
      controller: "cart",
      token: prestashop.static_token,
      ajax: 1,
    };
    const authorizedParams = [
      "action",
      "controller",
      "token",
      "ajax",
      "add",
      "update",
      "delete",
      "id_product",
      "id_product_attribute",
      "id_customization",
      "qty",
      "op", // up ou down
    ];
    // Si on n'a pas d'id produit, on sort
    if (!params.id_product) {
      console.error(`ljd_ajaxcart.action: id_product is undefined.`);
      return;
    }
    // Vérification de la validité des paramètres (simpliste)
    for (const [key, _] of Object.entries(params)) {
      if (!authorizedParams.includes(key)) {
        console.error(`ljd_ajaxcart.action: property ${key} is invalid.`);
        return;
      }
    }
    return Object.assign(mandatoryParams, params);
  },
  abortPreviousRequests: () => {
    var promise;
    while (promises.length > 0) {
			promise = promises.pop();
      promise.abort();
    }
  },
  increaseQuantity: function (params, elementToBeUpdated) {
    return this.do("increase", params, elementToBeUpdated);
  },
  decreaseQuantity: function (params, elementToBeUpdated) {
    return this.do("decrease", params, elementToBeUpdated);
  },
  add: function (params, elementToBeUpdated) {
    return this.do("add", params, elementToBeUpdated);
  },
  remove: function (params, elementToBeUpdated) {
    return this.do("delete", params, elementToBeUpdated);
  },
  actions: function (elementToBeUpdated) {
    const etbu = elementToBeUpdated || this.elementToBeUpdated;
    if (!etbu || typeof etbu !== "object") {
      console.error(`ljd_ajaxcart.action: please provide elements to be updated.`);
    }
    this.requestTemplates()
      .then((resp) => {
        // Remplacement de l'icone du panier
        if (resp && resp.preview && etbu.includes("updateCartIcon")) {
          $(".blockcart").replaceWith(resp.preview);
        }
        if (resp && resp.modal && etbu.includes("showModal")) {
          let el = $("#blockcart-modal");
          if (el.length === 0) {
						// Si pas de modal existante, on la crée
            $("body").append(resp.modal);
            el = $("#blockcart-modal");
          } else {
          	// Si une modal existe déjà, on remplace son contenu
						var mdl = document.createElement('div');
            mdl.innerHTML = resp.modal;
            document.querySelector('#blockcart-modal').innerHTML = mdl.querySelector('#blockcart-modal').innerHTML;
          }

          // Modif dans le cas d'un ajout multiple, bien afficher le premier produit dans l'encart vert
          if ($('#blockcart-modal .current .title').text() == '') {
            let realFirstProduct = $('#blockcart-modal .list-products .product:first-child').html();
            $('#blockcart-modal .list-products .product:first-child').remove();
            $('#blockcart-modal .current').html(realFirstProduct);
          }

          el.modal("show");
          // if (!el.length || typeof el.modal !== 'function') {
          // 	console.error(`ljd_Ajaxcart: Unable to display modal. Are you on cart page ?`);
          // 	return;
          // }
          // Affichage de la modal
        }
      })
      .fail((err) => {
        console.error("error", err);
      });
  },
  showModal: function () {
    this.actions(["showModal"]);
  },
  updateCartIcon: function () {
    this.actions(["updateCartIcon"]);
  },
  showModalAndUpdateCartIcon: function() {
    this.actions(["showModal", "updateCartIcon"]);
  },
  do: function (action, params, elementToBeUpdated) {
    this.elementToBeUpdated = elementToBeUpdated || ["updateCartIcon"];
    if (action in ["add", "increase", "decrease", "remove"] === true) {
      console.error(`ljd_Ajaxcart.action: action ${action} is invalid.`);
      return;
    }
    const query = this.prepareQuery(params);
    switch (action) {
      case "add": {
        query.add = 1;
        break;
      }
      case "increase": {
        query.update = 1;
        query.op = "up";
        break;
      }
      case "decrease": {
        query.update = 1;
        query.op = "down";
        break;
      }
      case "delete": {
        query.delete = 1;
        break;
      }
    }
    return this.doQuery(query);
  },
  requestTemplates: function () {
    const url = $(".blockcart").data("refresh-url");
    const requestData = {
      id_product: null,
      id_customization: null,
      id_product_attribute: null,
      action: "add-to-cart",
    };
    return $.post(url, requestData);
  },
  doQuery: function (query) {
		const defer = $.Deferred();
		this.abortPreviousRequests();
    $.ajax({
      url: prestashop.urls.pages.cart,
      method: 'POST',
      data: query,
      dataType: 'json',
      beforeSend: (jqXHR) => {
				promises.push(jqXHR);
			},
		})
      .success((resp) => {
        // Erreur lors du traitement
        if (resp.hasError) {
          defer.reject(resp.errors[0]);
          return;
        }
        // Maj des éléments HTML
        if (typeof this.elementToBeUpdated === "object") {
          this.actions(null, query);
        } else {
          prestashop.emit("updatedCart", {
            reason: resp,
          });
        }
        defer.resolve(true);
      })
      .fail(function (resp) {
        prestashop.emit("handleError", {
          eventType: "updateShoppingCart",
          resp: resp,
        });
      });
    return defer.promise();
  },
};

export default action;
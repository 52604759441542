import { debounce } from "lodash";

const bind = function (config) {
  let trigger = null;

  function updateInput($el, action) {
    const $wrapper = $el.closest(config.wrapper);
    const $input = $wrapper.find(config.input);

    const attributes = $wrapper.data();
    if (!attributes.idProduct) {
      console.error(
        `ljd_ajaxcart.bind.updateInput: unable to find id_product.`
      );
    }
    if (!$input.length) {
      console.error(
        `ljd_ajaxcart.bind.updateInput: unable to find input element.`
      );
    }
    const currentValue = parseInt($input.val());
    // Register old value
    let oldValue = $input.data("old-value");
    if (oldValue === undefined) {
      oldValue = currentValue;
      $input.data("old-value", oldValue);
    }
    // Retrait
    if (action == "down" && currentValue >= 1) {
      $input.val(currentValue - 1);
    }
    // AJout
    if (action == "up") {
      // Check stock
      const nextValue = currentValue + 1;
      $input.val(nextValue);
    }
    // console.error("update input", action);
    $input.trigger("input");
  }

  function debouncedUpdate(e) {
    const $input = $(e.target);
    const $wrapper = $input.closest(config.wrapper);

    // console.error("input update");
    if ($input.val() === 0) {
      console.error(`ljd_ajaxcart.bind: unable to find input element.`);
    }

    let availableStock;
    const attributes = $wrapper.data();
    let oldValue =
      $input.data("old-value") !== undefined
        ? parseInt($input.data("old-value"))
        : parseInt($input.val());
    if (!attributes.idProduct) {
      console.error(`ljd_ajaxcart.bind: unable to find id_product.`);
    }
    const newValue = Math.abs($input.val());
    // if (e.type === "keyup") {
    //   return false;
    // }
    const diff = newValue - oldValue;
    let op = diff > 0 ? "up" : "down";
    // Si la quantité demandée est de 0
    // On set le diff à 0 pour supprimer le produit
    if (newValue === 0) {
      op = "delete";
      // On affiche le bouton d'ajout au panier
      $wrapper.find(".qty-items-wrapper").removeClass("active");
    } else {
      // Si la difference est nulle, on sort
      if (diff === 0) {
        return;
      }
      // Vérification du stock
      availableStock = parseInt(attributes.productQuantity) - diff;
      const elMsg = $wrapper.find(".msg-out-of-stock");
      if (availableStock < 0) {
        if (elMsg.length === 1) {
          elMsg.css('display', 'block');
        }
        // const productName = $wrapper.find(".product-title a").text();
        // ljd_notification.toast.display(
        //   `
        //    Le produit **${productName}** n'est pas disponible dans la quantité souhaitée. Le stock maximal a été ajouté à votre panier. 
        //   `,
        //   { delay: 3000 }
        // );
        // $input.val(attributes.productQuantity + oldValue);
        // $input.trigger("input");
        return;
      } else if (elMsg.length === 1) {
        elMsg.css('display', 'none');
      }
    }
    // console.error(oldValue, newValue, Math.abs(diff), op);
    // On trigger la maj
    let promise;
    if (op === "down") {
      promise = ljd_ajaxCart.action.decreaseQuantity({
        id_product: attributes.idProduct,
        id_product_attribute: attributes.idProductAttribute,
        id_customization: attributes.idCustomization,
        qty: Math.abs(diff),
      });
    } else if (op === "up") {
      promise = ljd_ajaxCart.action.increaseQuantity({
        id_product: attributes.idProduct,
        id_product_attribute: attributes.idProductAttribute,
        id_customization: attributes.idCustomization,
        qty: Math.abs(diff),
      });
    } else if (op === "delete") {
      promise = ljd_ajaxCart.action.remove({
        id_product: attributes.idProduct,
        id_product_attribute: attributes.idProductAttribute,
        id_customization: attributes.idCustomization,
      });
    }
    // Callbacks
    if (promise) {
      promise.then((res) => {
        // On update les quantités
        if (res === true) {
          // Update old value
          $input.data("old-value", newValue);
          // Update stock
          $wrapper.data("productQuantity", availableStock);
          if (typeof config.events[trigger] === "function") {
            config.events[trigger]($wrapper, newValue);
          }
        }
      });
    }
  }

  // Clavier
  function bindEvents() {
    const body = $("body");
    // Bouton -
    const eventLess = $.Event("click");
    eventLess.target = config.less;
    body.trigger(eventLess);
    body.on("click", `${config.wrapper} ${config.less}`, (e) => {
      // console.error("button less pressed");
      trigger = ".input";
      updateInput($(e.target), "down");
    });
    // Bouton +
    const eventMore = $.Event("click");
    eventMore.target = config.more;
    body.trigger(eventMore);
    body.on("click", `${config.wrapper} ${config.more}`, (e) => {
      trigger = ".input";
      updateInput($(e.target), "up");
    });
    // Bouton add to cart
    const eventAddToCart = $.Event("click");
    eventAddToCart.target = config.addToCard;
    body.trigger(eventAddToCart);
    body.on("click", `${config.wrapper} ${config.addToCart}`, (e) => {
      trigger = config.addToCart;
      e.stopPropagation();
      // On cache le bouton
      $(e.target).siblings(".qty-items-wrapper").addClass("active");
      updateInput($(e.target), "up");
    });
    // Input
    // In
    const eventInputIn = $.Event("input");
    eventInputIn.target = config.input;
    body.trigger(eventInputIn);
    body.on("focusin", `${config.wrapper} ${config.input}`, (e) => {
      // Store input current value
      const $input = $(e.target);
      $input.data("old-value", $input.val());
    });
    // Out
    const eventInputOut = $.Event("input");
    eventInputOut.target = config.input;
    body.trigger(eventInputOut);
    body.on(
      "keyup input",
      `${config.wrapper} ${config.input}`,
      debounce(
        (e) => {
          if ($(e.target).val() === "") return;
          debouncedUpdate(e);
        },
        500,
        {
          leading: false,
          trailing: true,
        }
      )
    );
    body.on("keyup", `${config.wrapper} ${config.input}`, () => {
      trigger = ".input";
    });
  }

  bindEvents();
};

export { bind };
